import {defineStore} from 'pinia'

export const useGlobalSearchStore = defineStore('globalSearch', {
    state: () => ({
        searchInput: null,
        currentModule: null,
    }),

    actions: {
        setCurrentModule(currentModule) {
            this.currentModule = currentModule
        },
        setSearchInput(searchInput) {
            this.searchInput = searchInput
        },
    },
})
